import React, { useState, useEffect, useContext } from 'react'
import ShopifyClient from 'shopify-buy'
import Cookies from 'js-cookie'
import axios from 'axios'
import useSiteSettings from '../utils/useSiteSettings'


const client = ShopifyClient.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN,
  domain: process.env.GATSBY_SHOPIFY_STORE
})

// switched to local storage as cart JSON was too large for a cookie

const initialSiteState = {
  navStatus: 'closed',
  cartOpen: false,
  cartLoaded: false,
  cart: [],
  subCart: [],
  defaultOrder: 'sub',
  popUpOpen: false,
  quizPopUpOpen: false,
  variantChangeCount: 0,
}

const SiteContext = React.createContext({
  siteState: initialSiteState,
  setSiteState: undefined
})

const SiteStore = ({ children }) => {
  const [siteState, setSiteState] = useState(initialSiteState)
  const [init, setInit] = useState(false)

  useEffect(() => {
    if(init){
      localStorage.setItem('PP_CART', JSON.stringify(siteState.cart), { expires: 2 })
    }
  }, [siteState])

  useEffect(() => {
    if (Cookies.get('HIDE_PP_POP_UP')) {
      setSiteState(prevState => ({
        ...prevState,
        popUpOpen: false
      }))
    } else if (!Cookies.get('HIDE_PP_POP_UP')) {
      setSiteState(prevState => ({
        ...prevState,
        popUpOpen: true
      }))
    }
  }, [])

  const loadCartFromCookie = () => {
    const cartCookie = localStorage.getItem('PP_CART')
    if(cartCookie){
      setSiteState(prevState => ({
        ...prevState,
        cart: JSON.parse(cartCookie),
        cartLoaded: true
      }))
    } else {
      setSiteState(prev => ({...prev, cartLoaded: true}))
    }
    setInit(true)
  }

  const destroyCart = () => {
    Cookies.remove('PP_CHECKOUT_TYPE')
    Cookies.remove('PP_CHECKOUT_ID')
    localStorage.removeItem('PP_CART')
    setInit(true)
  }

  useEffect(() => {
    //load the cart from a cookie on hard refresh
    const type = Cookies.get('PP_CHECKOUT_TYPE')
    const id = Cookies.get('PP_CHECKOUT_ID')

    if(id && type === 'shopify'){
      client.checkout.fetch(id).then((checkout) => {
        // Do something with the checkout
        if(checkout.completedAt){
          destroyCart()
        } else {
          loadCartFromCookie()
        }
      })
    } else if(id && type === 'recharge') {
      axios({
        url: '/.netlify/functions/retrieve-recharge-checkout',
        method: 'POST',
        data: JSON.stringify({id: id})
      }).then(checkout => {
        if(checkout?.data?.checkout?.completed_at){
          destroyCart()
        } else {
          loadCartFromCookie()
        }
      })
    } else {
      loadCartFromCookie()
    }

  }, [])
  
  useEffect(() => {
    // console.log(window.location)
    document.documentElement.addEventListener('mouseout', e => {
      if(e.clientY <= 0 || e.clientX <= 0 || (e.clientX >= window.innerWidth || e.clientY >= window.innerHeight)){
        if ((Cookies.get('HIDE_PP_QUIZ_POP_UP')) || (!window.location?.pathname?.includes('/coffee')) ) {
          return
        } else if (!Cookies.get('HIDE_PP_QUIZ_POP_UP')) {
          setSiteState(prevSiteState => ({
            ...prevSiteState,
            quizPopUpOpen: true
          }))
        }
      }
    })
  }, [])

  return(
    <SiteContext.Provider value={{
      siteState: siteState,
      setSiteState: setSiteState
    }}>
      {children}
    </SiteContext.Provider>
  )
}

//hook to access siteState globally
const useSiteState = () => {
  const { siteState } = useContext(SiteContext)
  return siteState
}

//popUp functions

const usePopUp = () => {
  const {siteState, setSiteState} = useContext(SiteContext)

  const closePopUp = () => {
    setSiteState({
      ...siteState,
      popUpOpen: false,
    })
    Cookies.set('HIDE_PP_POP_UP', true, { expires: 7 })
  }

  const showQuizPopUp = () => {
    if (Cookies.get('HIDE_PP_QUIZ_POP_UP')) {
      return
    } else if (!Cookies.get('HIDE_PP_QUIZ_POP_UP')) {
      setSiteState({
        ...siteState,
        quizPopUpOpen: true
      })
    }
  }

  const closeQuizPopUp = () => {
    setSiteState({
      ...siteState,
      quizPopUpOpen: false
    })
    Cookies.set('HIDE_PP_QUIZ_POP_UP', true, { expires: 7 })
  }

  const countVariantChange = (loc) => {
    if (loc?.pathname?.includes('/coffee')) {
      setSiteState({
        ...siteState,
        variantChangeCount: siteState.variantChangeCount + 1
      })  
    }   
    if ((siteState.variantChangeCount > 2) && !Cookies.get('HIDE_PP_QUIZ_POP_UP')) {
      setSiteState({
        ...siteState,
        quizPopUpOpen: true,
        variantChangeCount: 0
      })  
    }
  }

  return {
    closePopUp,
    popUpOpen: siteState.popUpOpen,
    closeQuizPopUp,
    quizPopUpOpen: siteState.quizPopUpOpen,
    showQuizPopUp,
    countVariantChange
  }
}

export const prepareProductForTracking = (product, position, qty) => {
  // console.log({product})
  return {
    id: product?._rawShopify?.sku ?? product?._rawShopify?.defaultVariant?.sku, // SKU
    name: product?._rawShopify?.title, // Product title
    brand: "Pod & Parcel",
    // Not easy to weave category in here, so doing a workaround
    category: product?._rawShopify?.title === "Coffee Pods" ? "Coffee Pods" : "Unknown",
    variant: product?._rawShopify?.variantTitle ?? product?._rawShopify?.defaultVariant?.title,
    price: product?.subscribe ? product?.subPrice : (product?._rawShopify?.price ?? product?._rawShopify?.defaultVariant?.price),
    quantity: qty?.toString() ?? product?.qty?.toString(), // Only required for dl_view_cart
    list: product?.list ?? 'Unknown', // Not required for dl_view_cart; The list the product is displayed in
    product_id: product?._rawShopify?.productId?.toString(), // The product_id
    variant_id: product?._rawShopify?.variantId?.toString() ?? product?._rawShopify?.defaultVariant?.variantId?.toString(),// id or variant_id
    image: product?.fluid?.src?.replace("https:",""),
    position
  }
}

export const userProperties = {
	user_consent: "",
	visitor_type: "guest"
}

//cart functions

const useCart = () => {
  const {siteState, setSiteState} = useContext(SiteContext)
  const { minSalesMotivator } = useSiteSettings()

  const setCartOpen = newStatus => {

    const reducer = (accumulator, currentValue) => accumulator + ((currentValue.subscribe ? currentValue.subPrice : currentValue.price) * currentValue.qty)
    const total =  [...siteState.cart].reduce(reducer, 0)

    setSiteState({
      ...siteState,
      cartOpen: newStatus
    })

    if(!newStatus) return

    if(window.ElevarDataLayer.push){
        window.ElevarDataLayer.push({
          "event": "dl_view_cart",
          "user_properties": userProperties,
          "_elevar_internal": {
              "isElevarPush": true
          },
          "cart_total": total.toString(),
          "ecommerce": {
              "currencyCode": "AUD",
              "actionField": {
                  "list": "Shopping Cart"
              },
              "impressions": siteState?.cart?.map((product, index) => prepareProductForTracking(product, index + 1)),
          }
        })
    }
  }

  const setFreeCartItem = newStatus => {
    setSiteState({
      ...siteState,
      freeCartItem: newStatus
    })
  }

  const addItem = (item, openOnAdd, cartName) => {
    cartName = cartName === 'subCart' ? 'subCart' : 'cart'
    let itemIndex = siteState[cartName].findIndex(c => c.vId === item.vId)
    let newCart = siteState[cartName]
    if(itemIndex === -1){
      newCart = [...newCart, {
        ...item,
        list: window?.location?.pathname
      }]
      setSiteState({
        ...siteState,
        cartOpen: openOnAdd,
        [cartName]: newCart
      })
    }
    else{
      if(newCart[itemIndex].subscribe === item.subscribe){
        newCart[itemIndex].qty += item.qty
      }
      else{
        newCart[itemIndex].qty = item.qty
        newCart[itemIndex].subscribe = item.subscribe
      }
      setSiteState({
        ...siteState,
        cartOpen: openOnAdd,
        [cartName]: newCart
      })
    }
    if(window.ttq){
      window.ttq.track('AddToCart')
    }
    if(window.ElevarDataLayer.push){
      window.ElevarDataLayer.push({
          "event": "dl_add_to_cart",
          "user_properties": userProperties,
          "_elevar_internal": {
              "isElevarPush": true
          },
          "ecommerce": {
              "currencyCode": "AUD",
              "add": {
                  "actionField": {
                      'list': window.location.pathname
                  },
                  "products": [prepareProductForTracking(item, null, item.qty)]
              }
          }
      })
    }
  }

  const addMultipleItems = (items, openOnAdd, cartName ) => {
    cartName = cartName === 'subCart' ? 'subCart' : 'cart'
    // need to finish
  }

  const setSubscribe = (vId, subscribe, cartName) => {
    cartName = cartName === 'subCart' ? 'subCart' : 'cart'
    let itemIndex = siteState[cartName].findIndex(c => c.vId === vId)
    if(itemIndex === -1){
      return null
    }
    let newCart = siteState[cartName]
    newCart[itemIndex].subscribe = subscribe
    setSiteState({
      ...siteState,
      [cartName]: newCart
    })
    window.ElevarDataLayer.push({
      "event": "dl_add_to_cart",
      "user_properties": userProperties,
      "_elevar_internal": {
          "isElevarPush": true
      },
      "ecommerce": {
          "currencyCode": "AUD",
          "add": {
              "actionField": {
                  'list': window.location.pathname
              },
              "products": [prepareProductForTracking(newCart[itemIndex], null, newCart[itemIndex].qty)]
          }
      }
    })
  }

  const updateFrequency = (vId, frequency, cartName) => {
    cartName = cartName === 'subCart' ? 'subCart' : 'cart'
    let itemIndex = siteState[cartName].findIndex(c => c.vId === vId)
    if(itemIndex === -1){
      return null
    }
    let newCart = siteState[cartName]
    newCart[itemIndex].frequency = frequency
    setSiteState({
      ...siteState,
      [cartName]: newCart
    })
  }

  const updateAllFrequency = (vId, frequency, cartName) => {
    cartName = cartName === 'subCart' ? 'subCart' : 'cart'
    // let itemIndex = siteState[cartName].findIndex(c => c.vId === vId)
    // if(itemIndex === -1){
    //   return null
    // }
    // console.log(siteState[cartName].map(item => ({...item, frequency: frequency})))
    setSiteState({
      ...siteState,
      [cartName]: siteState[cartName].map(item => ({...item, frequency: frequency}))
    })
  }

  const updateQty = (vId, qty, cartName) => {
    cartName = cartName === 'subCart' ? 'subCart' : 'cart'
    let itemIndex = siteState[cartName].findIndex(c => c.vId === vId)
    if(itemIndex === -1){
      return null
    }
    let item = siteState[cartName][itemIndex]
    let originalQty = item.qty
    let newCart = siteState[cartName]
    newCart[itemIndex].qty = qty

    let type = originalQty < qty ? 'increase' : 'decrease' 

    console.log({originalQty, qty, type})
    setSiteState({
      ...siteState,
      [cartName]: newCart
    })
    if(window.ElevarDataLayer.push){
      window.ElevarDataLayer.push({
          "event": type === "increase" ? "dl_add_to_cart" : "dl_remove_from_cart",
          "user_properties": userProperties,
          "_elevar_internal": {
              "isElevarPush": true
          },
          "ecommerce": {
              "currencyCode": "AUD",
              [type === "increase" ? "add" : "remove"]: {
                  "actionField": {
                      'list': window.location.pathname
                  },
                  "products": [prepareProductForTracking(item, null, qty - originalQty)]
              }
          }
      })
    }
  }

  const removeItem = (vId, cartName, _rawShopify) => {
    cartName = cartName === 'subCart' ? 'subCart' : 'cart'
    let itemIndex = siteState[cartName].findIndex(c => c.vId === vId)
    if(itemIndex === -1){
      return null
    }
    let newCart = siteState[cartName]
    newCart.splice(itemIndex, 1)
    setSiteState({
      ...siteState,
      [cartName]: newCart
    })
    if(window.ElevarDataLayer.push){
      window.ElevarDataLayer.push({
          "event": "dl_remove_from_cart",
          "user_properties": userProperties,
          "_elevar_internal": {
              "isElevarPush": true
          },
          "ecommerce": {
              "currencyCode": "AUD",
              "remove": {
                  "actionField": {
                      'list': window.location.pathname
                  },
                  "products": [prepareProductForTracking(siteState[cartName][itemIndex], null, 1)]
              }
          }
      })
    }
  }

  const cartCount = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue.qty
    return [...siteState.cart].reduce(reducer, 0)
  }

  const cartTotal = () => {
    const reducer = (accumulator, currentValue) => accumulator + ((currentValue.subscribe ? currentValue.subPrice : currentValue.price) * currentValue.qty)
    return [...siteState.cart].reduce(reducer, 0)
  }

  return {
    cartOpen: siteState.cartOpen,
    setCartOpen: setCartOpen,
    cart: siteState.cart,
    cartLoaded: siteState?.cartLoaded,
    cartCount: cartCount,
    cartTotal: cartTotal,
    addItem: addItem,
    setSubcribe: setSubscribe,
    updateFrequency: updateFrequency,
    updateAllFrequency: updateAllFrequency,
    updateQty: updateQty,
    removeItem: removeItem
  }
}



//hook to set navStatus
const useSetNavStatus = () => {
  const {siteState, setSiteState} = useContext(SiteContext)

  const updateNavStatus = newStatus => {
    setSiteState({
      ...siteState,
      navStatus: newStatus
    })
  }

  return updateNavStatus
}

// hook to set defaultOrder
const useDefaultOrder = () => {
  const {siteState, setSiteState} = useContext(SiteContext)

  const setDefaultOrder = newOrder => {
    setSiteState({
      ...siteState,
      defaultOrder: newOrder
    })
  }

  return [
    siteState.defaultOrder,
    setDefaultOrder
  ]
}

export {
  SiteContext,
  SiteStore,
  useSiteState,
  useSetNavStatus,
  useDefaultOrder,
  useCart,
  usePopUp
}